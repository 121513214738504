import React from "react"
import { Link } from "gatsby"

import hslLogo from "../../../images/footer-logo.png"

import fbIcon from "../../../images/home-banner/facebook.svg"
import linkedinIcon from "../../../images/home-banner/linkedin.svg"
import indiamartIcon from "../../../images/home-banner/indimart.png"
import instagramIcon from "../../../images/home-banner/instagram.svg"
import mediumIcon from "../../../images/home-banner/medium.svg"
import twitterIcon from "../../../images/home-banner/twitter.svg"

import "./footer.scss"

const Footer = props => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-content">
          <div className="footer-content-col">
            <img src={hslLogo} alt="High Street Lighting" />
          </div>
          <div className="footer-divider" />
          <div className="footer-content-col">
            <Link to="/about" className="footer-link">
              ABOUT
            </Link>
            <Link to="/blog" className="footer-link">
              BLOG
            </Link>
            <Link to="/" className="footer-link">
              SITEMAP
            </Link>
            <Link to="/contact-us" className="footer-link" style={{textAlign: "left"}}>
              CONTACT US
            </Link>
          </div>
          <div className="footer-divider" />
          <div className="footer-content-col">
            <span>Address:</span>
            <span>
              <b />
            </span>
            <span>
              <b />
            </span>
            <span>
              <b />
            </span>
            <span>
              <b />
            </span>
            <span>
              <b />
            </span>
            <span>Ph. No:</span>
            <span>
              <b />
            </span>
            <span>Email:</span>
            <span>
              <b />
            </span>
            <span>Timing:</span>
          </div>
          <div className="footer-content-col" style={{marginLeft: "-1.5rem"}}>
            <span>Plot No. 10,</span>
            <span>2nd Floor,Sector 18, </span>
            <span>Electronic City,</span>
            <span>Gurugram (India)</span>
            <span>122001</span>
            <span>
              <b />
            </span>

            <span>+91-9110381150</span>
            <span>
              <b />
            </span>
            <span>
              <a
                href="mailto:sales@highstreetlighting.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                sales@highstreetlighting.com
              </a>
            </span>
            <span>
              <b />
            </span>
            <span>Mon. - Fri. 9AM - 6PM</span>
          </div>
        </div>
        <div className="follow-us">
          <span>FOLLOW US</span>
          <div className="follow-us-divider"></div>
          <div className="follow-us-icons">
            <Link
              to="https://www.facebook.com/highstreetlighting/"
              target="_blank"
              style={{ marginLeft: "0" }}
            >
              <img src={fbIcon} alt="facebook" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/high-street-lighting-private-limited/about/"
              target="_blank"
            >
              <img src={linkedinIcon} alt="linkedin" />
            </Link>
            <Link
              to="https://www.indiamart.com/highstreetlighting/"
              target="_blank"
            >
              <img src={indiamartIcon} alt="indiamart" />
            </Link>
            <Link
              to="https://instagram.com/high_street_lighting?igshid=i0p8rl1rf5gr"
              target="_blank"
            >
              <img src={instagramIcon} alt="instagram" />
            </Link>
            <Link to="https://medium.com/@highstreetlightings" target="_blank">
              <img src={mediumIcon} alt="medium" />
            </Link>
            <Link to="https://twitter.com/HighStreetLigh1?s=09" target="_blank">
              <img src={twitterIcon} alt="medium" />
            </Link>
          </div>
        </div>
      </div>
      <div className="copyright">
        <span>
          designed by{" "}
          <a
            href="https://www.digitalgrahak.in/"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            Digital Grahak
          </a>{" "}
          © , {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  )
}

export default Footer
