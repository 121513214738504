import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header/header"
import "./layout.scss"
import Footer from "./footer/footer"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} homepage={props.home} />
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        {props.home ? <div></div>: <div style={{marginTop:"6rem"}}></div>}
        <main>{props.children}</main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


// ©