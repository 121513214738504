import { Link, navigate } from "gatsby"
import PropTypes, { nominalTypeHack } from "prop-types"
import React, { useEffect } from "react"
import useDarkMode from "use-dark-mode"

import NavbarLogo from "../../../images/hslLogo.svg"
import bulbOff from "../../../images/header-product/bulb_off.svg"
import bulbOn from "../../../images/header-product/bulb_on.svg"
import "./header.scss"

import phoneIcon from "../../../images/header-product/phone.svg"

import integratedSolarStreetLight from "../../../images/header-product/integrated-solar-street-light.svg"
import solarFan from "../../../images/header-product/solar-fan.svg"
import homeLightingSystem from "../../../images/header-product/home-lighting-system.svg"
import lithiumIonBatteryPacks from "../../../images/header-product/lithium-ion-battery-packs.svg"
import ledStreetLight from "../../../images/header-product/led-street-light.svg"
import highBayLight from "../../../images/header-product/high-bay-light.svg"
import eccoDiva from "../../../images/header-product/ecco-diva.svg"
import acDcEmergencyBulb from "../../../images/header-product/ac-dc-emergency-bulb.svg"

import clickHere from "../../../images/header-product/click_here.svg"
import thankYou from "../../../images/header-product/thank_you.svg"

export default class Header extends React.Component {
  state = {
    color: "transparent",
    logoSize: "40%",
    thankYouFilter: true,
  }

  flag = false

  componentDidMount() {
    if (!this.props.homepage) {
      this.setState({
        color: "white",
        logoSize: "20%",
        thankYouFilter: false,
      })
    } else {
      window.addEventListener("scroll", this.listenScrollEvent)
      this.flag = false
    }
  }

  componentWillUnmount() {
    if (this.flag) {
      window.removeEventListener("scroll", this.listenScrollEvent)
    }
  }

  listenScrollEvent = e => {
    if (window.scrollY < 100) {
      this.setState({
        color: "transparent",
        logoSize: "40%",
        thankYouFilter: true,
      })
    } else {
      this.setState({ color: "white", logoSize: "20%", thankYouFilter: false })
    }
  }

  onSearchSubmit = event => {
    if (event.key === "Enter") {
      navigate("/")
    }
  }

  render() {
    return (
      <HSLHeader
        color={this.state.color}
        logoSize={this.state.logoSize}
        siteTitle={this.props.siteTitle}
        filter={this.state.thankYouFilter}
      />
    )
  }
}

const HSLHeader = props => {
  const darkMode = useDarkMode(false)

  return (
    <header
      className="header"
      style={{
        backgroundColor:
          props.color === "white"
            ? darkMode.value
              ? "rgba(0, 0, 0, 0.6)"
              : props.color
            : props.color,
      }}
    >
      <div className="navbar">
        <Link to="/" className="navbar-logo" style={{ width: props.logoSize }}>
          <img src={NavbarLogo} alt={props.siteTitle} />
        </Link>
        <ul className="navbar-buttons">
          <NavbarButton hoverBool={true} to="/">
            <span
              style={{
                color:
                  props.color === "white"
                    ? darkMode.value
                      ? "white"
                      : "black"
                    : "black",
              }}
            >
              Home
            </span>
          </NavbarButton>
          <li className="navbar-button nav-product-dropdown">
            <Link
              className="nav-link"
              style={{
                color:
                  props.color === "white"
                    ? darkMode.value
                      ? "white"
                      : "black"
                    : "black",
              }}
            >
              Products
            </Link>
            <div
              className="nav-product-dropdown-content"
              style={
                props.color === "white" ? { top: "4rem" } : { top: "4.7rem" }
              }
            >
              <div className="nav-product-dropdown-content-container">
                <Link
                  className="product-link"
                  to="/products/all-in-one-solar-street-light"
                >
                  <div className="product-link-img">
                    <img src={integratedSolarStreetLight} />
                  </div>
                  <div className="product-link-text">
                    All in One Solar Street Light
                  </div>
                </Link>
                <Link
                  className="product-link"
                  to="/products/lithium-ion-cell-and-battery-pack"
                >
                  <div className="product-link-img">
                    <img src={lithiumIonBatteryPacks} />
                  </div>
                  <div className="product-link-text">
                    Lithium Ion Battery Packs
                  </div>
                </Link>
                <Link
                  className="product-link"
                  to="/products/solar-home-lighting-system"
                >
                  <div className="product-link-img">
                    <img src={homeLightingSystem} />
                  </div>
                  <div className="product-link-text">
                    Solar Home Lighting System
                  </div>
                </Link>
                <Link className="product-link" to="/products/led-street-light">
                  <div className="product-link-img">
                    <img src={ledStreetLight} />
                  </div>
                  <div className="product-link-text">LED Street Light</div>
                </Link>
                <Link className="product-link" to="/products/high-bay-light/">
                  <div className="product-link-img">
                    <img src={highBayLight} />
                  </div>
                  <div className="product-link-text">High Bay Light</div>
                </Link>
                <Link className="product-link" to="/products/ecco-diva">
                  <div className="product-link-img">
                    <img src={eccoDiva} />
                  </div>
                  <div className="product-link-text">Ecco Diva</div>
                </Link>
                <Link className="product-link" to="/products/solar-fan">
                  <div className="product-link-img">
                    <img src={solarFan} />
                  </div>
                  <div className="product-link-text">Solar Fan</div>
                </Link>
                <Link
                  className="product-link"
                  to="/products/ac-dc-emergency-bulb"
                >
                  <div className="product-link-img">
                    <img src={acDcEmergencyBulb} />
                  </div>
                  <div className="product-link-text">
                    Industrial Emergency Bulb {"&"} Tubelight
                  </div>
                </Link>
              </div>
            </div>
          </li>
          <NavbarButton hoverBool={true} to="/about/">
            <span
              style={{
                color:
                  props.color === "white"
                    ? darkMode.value
                      ? "white"
                      : "black"
                    : "black",
              }}
            >
              About
            </span>
          </NavbarButton>
          <NavbarButton hoverBool={true} to="/contact-us#projects">
            <span
              style={{
                color:
                  props.color === "white"
                    ? darkMode.value
                      ? "white"
                      : "black"
                    : "black",
              }}
            >
              Our Projects
            </span>
          </NavbarButton>
        </ul>
        <div className="navbar-button navbar-call-us">
          <a href="tel:+919110381150">
            <img src={phoneIcon} />
            <span>Call Us</span>
          </a>
        </div>
        <button
          className="navbar-dark-mode-button"
          onClick={() => {
            darkMode.toggle()
            // setTimeout(
            //   function () {
            //     if (!darkMode.value) {
            //       if (localStorage.getItem("darkMode")) {
            //         darkMode.disable()
            //       }
            //     }
            //   }.bind(this),
            //   20000
            // )
          }}
        >
          <img
            src={darkMode.value ? bulbOn : bulbOff}
            style={{ maxWidth: "4rem" }}
          />
        </button>
        {darkMode.value ? (
          <img
            src={thankYou}
            style={
              props.filter === false
                ? { maxWidth: "10rem" }
                : { maxWidth: "10rem", filter: "invert(1)" }
            }
          />
        ) : (
          <img src={clickHere} style={{ maxWidth: "10rem" }} />
        )}
      </div>
    </header>
  )
}

const NavbarButton = props => {
  var classes
  if (props.hoverBool) {
    classes = "navbar-button hover-active"
  } else {
    classes = "navbar-button hover-inactive"
  }
  return (
    <li className={classes}>
      <Link to={props.to} className="nav-link">
        {props.children}
      </Link>
    </li>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
